import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '@/utils/cn'
import Spinner from './Spinner'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-full text-base leading-tight font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 font-bold',
  {
    variants: {
      variant: {
        primary:
          'bg-primary-foreground text-primary-text hover:bg-primary-foreground/80',
        danger: 'bg-red-foreground text-primary hover:bg-red-foreground/80',
        secondary:
          'border-2 border-black bg-primary hover:bg-accent hover:opacity-80',
        ghost: 'bg-none hover:bg-accent hover:text-accent-primary',
        link: 'font-normal normal-case text-primary-foreground underline underline-offset-4 hover:opacity-80 p-0',
      },
      size: {
        link: ' p-0',
        default: ' px-4 py-3',
        sm: ' px-3',
        lg: ' px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
  },
)

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & { loading?: boolean }

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, size, variant, className, loading, ...props }, ref) => {
    const linkSize = variant === 'link' ? 'link' : undefined
    return (
      <button
        ref={ref}
        className={cn(
          buttonVariants({
            variant,
            size: size || linkSize,
          }),
          className,
        )}
        {...props}
      >
        {!loading ? children : <Spinner />}
      </button>
    )
  },
)

Button.displayName = 'Button'
