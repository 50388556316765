'use client'
import { CookieConstants, languages, SupportedLocales } from '@/constants'
import { messages as untypedMessages } from '@/i18n'
import { getCookie, setCookie } from '@/utils/cookie'
import * as React from 'react'
import { createContext, ReactNode, useMemo, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { CustomFormats, IntlConfig, IntlProvider } from 'react-intl'

type ContextType = {
  locale: SupportedLocales
  setLocale: (value: SupportedLocales) => void
}

const LocaleContext = createContext<ContextType>({
  locale: languages.sv,
  setLocale: () => {},
})

const formats: CustomFormats = {
  number: {
    currency: {
      currency: 'SEK',
      style: 'currency',
    },
  },
}

type LocaleProviderProps = {
  initialLocale: SupportedLocales
}

const LocaleProvider: React.FC<
  React.PropsWithChildren<LocaleProviderProps>
> = ({ children, initialLocale }): ReactNode => {
  const [locale, setLocale] = useState(initialLocale)
  React.useEffect(() => {
    if (getCookie(CookieConstants.Language)) {
      setCookie(CookieConstants.Language, initialLocale)
    }
  }, [])
  const setLocaleState = (locale: SupportedLocales) => {
    setCookie(CookieConstants.Language, locale)
    setLocale(locale)
  }
  const messages = untypedMessages as {
    [key: string]: IntlConfig['messages']
  }
  const localeContextProps = useMemo(() => {
    return {
      locale,
      setLocale: setLocaleState,
    }
  }, [locale])
  return (
    <LocaleContext.Provider value={localeContextProps}>
      <HelmetProvider>
        <Helmet htmlAttributes={{ lang: locale }} />
      </HelmetProvider>
      <IntlProvider
        key={locale}
        formats={formats}
        locale={locale}
        messages={messages[locale]}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  )
}

export default LocaleProvider
export const useLocaleContext = () => React.useContext(LocaleContext)
